<template>
	<div class="element-box">
		<h5 class="form-header">
			Офисы работы сотрудника {{ user.name }}
		</h5>
		<div class="form-desc">
			Измените необходимые данные и нажмите сохранить
		</div>

		<div>
			<div v-for="(o, i) in userOffices" :key="i" class="row mb-2">
				<div class="col">
					<div class="row">
						<div class="col-sm-5">
							<b-form-select
								v-model="o.id"
								:options="officesOptions"
							></b-form-select>
						</div>
						<div class="col-sm-5">
							<b-form-select
								v-model="o.userFunction"
								:options="functionsOptions"
							></b-form-select>
						</div>
						<div class="col-sm-2">
							<b-form-select
								v-model="o.userCapacity"
								:options="capacityOptions"
							></b-form-select>
						</div>
					</div>
				</div>
				<div class="col col-auto">
					<b-button class="btn btn-danger" @click="onOfficeDeleteClick(o)">
						Удалить
					</b-button>
				</div>
			</div>
			<div>
				<b-button class="btn btn-grey" @click="onOfficeAddClick">
					Добавить
				</b-button>
			</div>
		</div>
		<div class="form-buttons-w">
			<b-button
				class="btn btn-primary"
				type="button"
				variant="primary"
				name="button"
				@click="saveOffices()"
			>
				Сохранить
			</b-button>
			<b-button class="btn btn-grey" @click="backToOfficesTable">Назад</b-button>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'OfficesUpdateForm',
	data() {
		return {
			filters: {},
			backToUrl: '',
			user: {
				id: '',
				name: '',
			},
			userOffices: [],
			officesOptions: [],
			functionsOptions: [
				{value: 'consultation', text: 'Консультации'},
				{value: 'drr', text: 'ДРР'},
			],
			capacityOptions: [
				{value: 0, text: '0'},
				{value: 25, text: '25'},
				{value: 50, text: '50'},
				{value: 100, text: '100'},
				{value: 150, text: '150'},
				{value: 175, text: '175'},
			],
		};
	},

	computed: {
		...mapGetters([
			'passportUsers',
			'staffOffices',
			'helpdeskBranches', 
		]),
	},

	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'HELPDESK_GET_BRANCHES_FROM_API',
			'HELPDESK_GET_STAFF_OFFICES_FROM_API',
			'HELPDESK_SAVE_STAFF_OFFICES',
			'ADD_NOTIFICATION_TO_QUERY',
		]),

		backToOfficesTable() {
			if (this.backToUrl !== '') {
				this.$router.push({path: decodeURI(this.backToUrl)});
			} else {
				this.$router.push({name: routeNames.passport.offices.read, query: this.filters});
			}
		},

		async getOffices() {
			await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({userId: this.user.id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудника'
				});
			});
		},

		async saveOffices() {
			const officesData = {
				userId: this.user.id,
				offices: this.userOffices,
			};

			await this.HELPDESK_SAVE_STAFF_OFFICES(officesData).then(async () => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Успешно'});
				await this.getOffices();

				let userOffices = [];
				this.userOffices = [];

				if (this.staffOffices && this.staffOffices.length) {
					userOffices = this.staffOffices[0].offices;
				}

				this.userOffices = userOffices;
			}).catch((e) => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка'
				});
			});
		},

		onOfficeAddClick() {
			this.userOffices.push({id: null, userFunction: 'consultation', userCapacity: 100});
		},

		onOfficeDeleteClick(office) {
			const idAddedOffice = this.userOffices.findIndex(
				o => o.id === office.id && o.userFunction === office.userFunction
			);
			if (idAddedOffice > -1) {
				this.userOffices.splice(idAddedOffice, 1);
			}
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				if(q === 'backTo' || q === 'back_to') {
					this.backToUrl = this.$route.query[q];
				} else {
					this.filters[q] = this.$route.query[q];
				}
			}
		}

		if (this.$route.params.user_id !== '0') {
			await this.GET_USERS_FROM_API({id: this.$route.params.user_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении пользователя'
				});
			});
			this.user = Object.assign({}, this.passportUsers[0]);

			await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000, project: 'all'}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка филиалов'
				});
			});

			await this.getOffices();
			
			let userOffices = [];

			if (this.staffOffices && this.staffOffices.length) {
				userOffices = this.staffOffices[0].offices;
			}

			this.userOffices = userOffices;

			for (let i = 0; i < this.helpdeskBranches.length; i++) {
				this.officesOptions.push({
					value: this.helpdeskBranches[i].id,
					text: this.helpdeskBranches[i].name,
				});
			}

			this.loaded = true;
		}
	},
};
</script>

<style>
</style>
