<template>
  <div class="element-box">
    <h5 class="form-header">
      Файлы записи рабочих смен
    </h5>
    <div class="table-responsive">
      <div class="controls-above-table">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <b-form-group label="Офис"
				              id="offices-input-group" 
				              label-for="office-select"
			            >
				        <b-form-tags id="office-select" v-model="officeTags" no-outer-focus class="mb-2">
					        <template v-slot="{ tags, disabled, addTag, removeTag }">
						        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag
                          @remove="onOfficeDeleteClick({ option: tag, removeTag: removeTag })"
                          :disabled="disabled"
                          variant="info"
                        >
                        {{ JSON.parse(tag).name }}
                        </b-form-tag>
                      </li>
						        </ul>

						        <b-dropdown v-if="!officeLoading && branchOptions.length > 0" class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							        <template #button-content>
								        Выбор офисов
							        </template>
							        <b-dropdown-item-button
									      v-for="branch in branchOptions"
									      :key="branch.id"
									      @click="onOfficeAddClick({ option: branch, addTag: addTag })"
							        >{{ branch.name }}
							        </b-dropdown-item-button>
						        </b-dropdown>
                    <div v-else class="text-center">
                      <span v-if="officeLoading">Загрузка...</span>
                      <span v-if="!officeLoading && branchOptions.length === 0">Не найдено офисов</span>
                    </div>
					        </template>
				        </b-form-tags>
			        </b-form-group>
              <b-form-group label="Сотрудник" 
				              id="users-input-group" 
				              label-for="user-select"
			            >
				        <b-form-tags id="user-select" v-model="managerTags" no-outer-focus class="mb-2">
					        <template v-slot="{ tags, disabled, addTag, removeTag }">
						        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag
                          @remove="onUserDeleteClick({ option: tag, removeTag: removeTag })"
                          :disabled="disabled"
                          variant="info"
                        >
                        {{ JSON.parse(tag).name }}
                        </b-form-tag>
                      </li>
						        </ul>

						        <b-dropdown v-if="!userLoading && userOptions.length > 0" class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							        <template #button-content>
								        Выбор сотрудников
							        </template>
							        <b-dropdown-item-button
									      v-for="user in userOptions"
									      :key="user.id"
									      @click="onUserAddClick({ option: user, addTag: addTag })"
							        >{{ user.name }}
							        </b-dropdown-item-button>
						        </b-dropdown>
                    <div v-else class="text-center">
                      <span v-if="userLoading">Загрузка...</span>
                      <span v-if="!userLoading && filters.office_ids.length === 0">Необходимо выбрать офис</span>
                      <span v-if="!userLoading && filters.office_ids.length > 0 && (userOptions.length === 0 && managerTags.length === 0)">В выбранном офисе нет сотрудников</span>
                    </div>
					        </template>
				        </b-form-tags>
			        </b-form-group>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="date-from-filter">Дата от</label>
                  <b-form-datepicker
                      id="date-from-filter"
                      placeholder="Выберите дату от.."
                      v-model="filters.date_from"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      reset-button
                      @input="applyFiltersToRoute"
                      locale="ru"
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="date-to-filter">Дата до</label>
                  <b-form-datepicker
                      id="date-to-filter"
                      placeholder="Выберите дату до.."
                      v-model="filters.date_to"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      reset-button
                      @input="applyFiltersToRoute"
                      locale="ru"
                  ></b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <form class="form-inline justify-content-sm-end">
              <b-form-select
                  id="okk-files-get-limit"
                  name="okk-files-get-limit"
                  v-model="filters.paginate"
                  :options="limits"
                  @change="applyFiltersToRoute"
                  class="form-control form-control-sm rounded bright"
              ></b-form-select>
            </form>
          </div>
        </div>
      </div>
      <table v-if="viewTable" class="table table-lightborder">
        <thead>
          <tr>
            <th>
              Дата
            </th>
            <th>
              Офис
            </th>
            <th>
              Сотрудник
            </th>
            <th>
              Длительность
            </th>
            <th>
              Время старта
            </th>
            <th>
              Время окончания
            </th>
            <th>
              Ссылка на запись
            </th>
            <th>
              Статус
            </th>
            <th>
              Просмотр
            </th>
            <th>
              Запись битая?
            </th>
          </tr>
        </thead>
        <tbody v-for="file in okkFiles" :key="file.id">
          <tr>
            <td>
              {{ file.date }}
            </td>
            <td v-html="findUserOfficeTransfer(file)"></td>
            <td>
              {{ file.manager_name }}
            </td>
            <td>
              {{ file.duration }}
            </td>
            <td>
              {{ file.time }}
            </td>
            <td>
              {{ file.time_end }}
            </td>
            <td>
              {{ file.link }}
              <b-button
                  size="sm"
                  variant="default"
                  class="mb-2 icon-button"
                  @click="copyLink(file.link)"
                  v-if="file.link"
              >
                <b-icon icon="files"></b-icon>
              </b-button>
            </td>
            <td>
              {{ file.status === 'is_recorded' ? 'готово' : (file.status === 'not_recorded' ? 'в процессе' : '') }}
            </td>
            <td>
              <b-link
                  size="sm"
                  variant="default"
                  class="mb-2 icon-button"
                  target="_blank"
                  :href="file.link"
                  v-if="file.link"
              >
                <b-icon icon="upload"></b-icon>
              </b-link>
              <b-button
                  size="sm"
                  variant="default"
                  class="mb-2 icon-button"
                  v-if="file.link"
                  @click="showVideo(file.id)"
              >
                <b-icon icon="play-fill"></b-icon>
              </b-button>

              <b-modal
                  :id="'video-file-'+file.id"
                  centered
                  title="Видео"
                  size="lg"
                  hide-footer
              >
                <video-player class="video-player-box"
                  :playsinline="true"
                  :options="{
                    autoplay: false,
                    muted: false,
                    controls: true,
                    language: 'en',
                    height: '490',
                    playbackRates: [0.5, 0.7, 1.0, 1.25, 1.5],
                    sources: [{
                      type: 'video/mp4',
                      src: file.link
                    }]
                  }"
                />
              </b-modal>
            </td>
            <td>
              <b-form-checkbox
                  :id="`checkbox-${file.id}`"
                  :name="`checkbox-${file.id}`"
                  v-model="file.corrupted"
                  value="true"
                  unchecked-value="false"
                  @change="handleCheckboxChange(file.id, file.status, file.corrupted)"
              >
              </b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="globalLoading" class="loader-container">
        <span class="loader"></span>
      </div>
    </div>
    <div class="controls-below-table">
      <div class="table-records-info">

      </div>
      <div class="table-records-pages">
        <ul>
          <li>
            <a href="#" @click="previous" v-if="canGetPrev">Назад</a>
          </li>
          <li>
            <a href="#" @click="next" v-if="canGetNext && okkFiles.length === filters.paginate">Далее</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'FileTable',
	computed: {
		...mapGetters([
			'okkFiles', 'okkUsers', 'helpdeskBranches'
		]),
	},
	data() {
		return {
      officeTags: [],
      managerTags: [],
			filters: {
				date_from: null,
				date_to: null,
				office_ids: [],
				manager_ids: [],
        video: null,
				paginate: 50,
				page: 1,
			},
			limits: [
				{ value: 50, text: 'Записей на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			branchOptions: [],
			userOptions: [],
			userOfficeId: null,
			canGetNext: true,
			canGetPrev: false,
      globalLoading: false,
      officeLoading: false,
      userLoading: false,
      viewTable: false,
      totalFiles: -1
		};
	},
	methods: {
		...mapActions([
			'OKK_GET_FILES_FROM_API', 'OKK_UPDATE_FILES_FROM_API', 'HELPDESK_GET_BRANCHES_FROM_API', 'OKK_GET_USERS_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

    async onUserDeleteClick({option, removeTag}) {
      this.viewTable = false;
			let userToRemove = JSON.parse(option);
			this.userOptions.push(userToRemove);
			this.userOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));

			removeTag(option);
      this.filters.manager_ids.splice(this.filters.manager_ids.indexOf(String(userToRemove.id)), 1);
      await this.applyFiltersToRoute();
		},

    async onUserAddClick({option, addTag}) {
      this.viewTable = false;
			this.filters.manager_ids.push(String(option.id));

			let id = this.userOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.userOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
      await this.applyFiltersToRoute();
		},

    async onOfficeAddClick({option, addTag}) {
      this.viewTable = false;
			this.filters.office_ids.push(String(option.id));

			let id = this.branchOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.branchOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
      this.filters.manager_ids = [];
      this.$router.replace({ query: this.filters });
      await this.filterOffice();
      await this.applyFiltersToRoute();
		},

    async onOfficeDeleteClick({option, removeTag}) {
      this.viewTable = false;
      let officeToRemove = JSON.parse(option);
			this.branchOptions.push(officeToRemove);
			this.branchOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));

			removeTag(option);
      this.filters.office_ids.splice(this.filters.office_ids.indexOf(String(officeToRemove.id)), 1);
      this.filters.manager_ids = [];
      this.$router.replace({ query: this.filters });
      await this.filterOffice();
      await this.applyFiltersToRoute();
    },

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.getFiles();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getFiles();
			if(this.okkFiles.length < this.filters.paginate) {
				this.canGetNext = false;
			}
		},

		copyLink(link) {
			navigator.clipboard.writeText(link);
		},

		showVideo(fileId) {
      if (fileId !== this.filters.video) {
        let query = {};
        if (fileId) Object.assign(query, { video: fileId });
        if (this.filters.date_from) Object.assign(query, { date_from: this.filters.date_from });
        if (this.filters.date_to) Object.assign(query, { date_to: this.filters.date_to });
        if (this.filters.office_ids && this.filters.office_ids.length > 0) {
          Object.assign(query, { office_ids: this.filters.office_ids.join(',') });
        }
        if (this.filters.manager_ids && this.filters.manager_ids.length > 0) {
          Object.assign(query, { manager_ids: this.filters.manager_ids.join(',') });
        }
        this.$router.push({ path: '/okk/files', query: query }).catch(()=>{});
      }
			this.$bvModal.show('video-file-' + fileId);
		},

		async handleCheckboxChange(id, status, corrupted) {
			await this.OKK_UPDATE_FILES_FROM_API({id, status, corrupted}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении файла'
				});
			});
		},

		findUserOfficeTransfer(file) {
      let checkOffice = this.filters.office_ids.includes(file.office_id.toString());
      return checkOffice ? file.office_name : `<span style="color: #0073ff">Перевод ${file.office_name}</span>`;
		},

		async getFiles() {
			await this.OKK_GET_FILES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка файлов' });
			});
      this.totalFiles = this.okkFiles.length;
		},
    
		async getBranches() {
      this.officeLoading = true;
			await this.HELPDESK_GET_BRANCHES_FROM_API({}).then(() => {
        for (let i = 0; i < this.helpdeskBranches.length; i++) {
          this.branchOptions.push({
            id: this.helpdeskBranches[i].id,
            name: this.helpdeskBranches[i].name
          });
        }
        this.branchOptions.sort((a, b) => a.name.localeCompare(b.name));
      }).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов'
				});
			}).finally(() => this.officeLoading = false);
		},

		async getUsers(office_ids = null) {
			await this.OKK_GET_USERS_FROM_API({office_ids}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении ОКК пользователей'
				});
			});
		},

		async filterOffice() {
      this.userLoading = true;
      this.userOptions = [];
      this.managerTags = [];
      let office_ids = this.filters.office_ids;
      await this.OKK_GET_USERS_FROM_API({office_ids}).then(() => {
        for (let i = 0; i < this.okkUsers.length; i++) {
          let item = { id: this.okkUsers[i].id, name: this.okkUsers[i].name };
          if (this.filters.manager_ids.includes(this.okkUsers[i].id)) {
            this.managerTags.push(item);
          } else {
            this.userOptions.push(item);
          }
        }
      }).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении ОКК пользователей'
				});
			}).finally(() => this.userLoading = false);
		},

    async applyFiltersToRoute() {
      this.viewTable = false;

      let query = {};
      if (this.filters.date_from) Object.assign(query, { date_from: this.filters.date_from });
      if (this.filters.date_to) Object.assign(query, { date_to: this.filters.date_to });
      if (this.filters.office_ids.length > 0) {
        Object.assign(query, { office_ids: this.filters.office_ids.join(',') });
      }
      if (this.filters.manager_ids.length > 0) {
        Object.assign(query, { manager_ids: this.filters.manager_ids.join(',') });
      }
      this.$router.push({ path: '/okk/files', query: query }).catch(()=>{});
      await this.applyFilters();
		},

		async applyFilters() {
      if (this.filters.date_from && this.filters.date_to && this.filters.office_ids.length > 0) {
        this.globalLoading = true;
        this.filters.page = 1;
        await this.getFiles().finally(() => this.globalLoading = false);
        this.viewTable = this.totalFiles >= 0;
      }
		},

    async mapQuery() {
      let date = new Date(),
        //date_to = new Date(date.getFullYear(), date.getMonth() + 1, 0),
        date_from = new Date(date.getFullYear(), date.getMonth(), 1);

      this.filters.date_from = this.$moment(date_from).format('YYYY-MM-DD');
      this.filters.date_to = this.$moment(date).format('YYYY-MM-DD');
    
      if (this.$route.query.video) this.filters.video = this.$route.query.video;
      if (this.$route.query.date_from) this.filters.date_from = this.$route.query.date_from;
      if (this.$route.query.date_to) this.filters.date_to = this.$route.query.date_to;
      if (this.$route.query.office_ids && this.$route.query.office_ids.length > 0) {
        this.filters.office_ids = this.$route.query.office_ids.split(',');
      }
      if (this.$route.query.manager_ids && this.$route.query.manager_ids.length > 0) {
        this.filters.manager_ids = this.$route.query.manager_ids.split(',');
      }

      this.viewTable = false;
      this.globalLoading = true;
      await this.getBranches();
      if (this.filters.office_ids.length > 0) {
        this.branchOptions = this.branchOptions.filter((e) => {
          if (this.filters.office_ids.includes(String(e.id))) {
            this.officeTags.push(e);
          } else {
            return e;
          }
        });
        await this.filterOffice();
      }
      await this.applyFilters();
      if (this.okkFiles.length > 0 && this.filters.video) {
        this.showVideo(this.filters.video);
      }
      this.globalLoading = false;
    },
	},
	async mounted() {
    await this.mapQuery();
  }
};
</script>
<style scoped>
.icon-button {
  margin-bottom: 5px !important;
  background-color: #fff;
  color: #000;
}
.loader-container {
  display: flex;
  justify-content: center;
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #0a7cf8;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #98c9fd;
}
@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}
@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}
</style>