<template>
	<div class="element-box">
		<b-form @submit.stop.prevent="submit" class="user-update-form">
			<h5 class="form-header">
				Изменение данных пользователя
			</h5>
			<div class="form-desc">
				Измените необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Логин пользователя: {{ user.login }}<br>
					Имя пользователя: {{ user.name }}<br>
					<div v-if="user.userPermissions.length > 0">
						Права пользователя:
						<div v-for="perm in user.userPermissions" :key="perm.id">{{ perm.name }}</div>
					</div>
					<div v-if="user.groups.length > 0">
						Группы пользователя:
						<div v-for="group in user.groups" :key="group">{{ group }}</div>
					</div>
				</div>
			</b-modal>

			<b-form-group id="login-input-group" label="Логин пользователя" label-for="user-login-input">
				<b-form-input
						id="user-login-input"
						v-model="user.login"
						placeholder="Введите логин пользователя"
						:disabled="!passportSU"
				></b-form-input>
			</b-form-group>

			<b-form-group id="password-input-group" v-if="passportSU" label="Пароль пользователя"
										label-for="user-password-input">
				<b-form-input
						id="user-password-input"
						v-model="user.password"
						placeholder="Введите пароль пользователя"
				></b-form-input>
			</b-form-group>

			<b-form-group id="name-input-group" label="Имя пользователя" label-for="user-name-input">
				<b-form-input
						id="user-name-input"
						v-model="user.name"
						placeholder="Введите имя пользователя"
						:disabled="!passportSU"
				></b-form-input>
			</b-form-group>

			<b-form-group id="tg-id-input-group" label="ID аккаунта в telegram"
										v-show="!IS_EXTERNAL() && this.user.is_service === 0"
										label-for="user-tg-id-input">
				<b-form-input
						id="user-tg-id-input"
						v-model="user.tgId"
						placeholder="123456789"
				></b-form-input>
			</b-form-group>

			<b-form-group id="tg-login-input-group" label="Логин аккаунта в telegram"
										v-show="!IS_EXTERNAL() && this.user.is_service === 0"
										label-for="user-tg-login-input">
				<b-form-input
						id="user-tg-login-input"
						v-model="user.tgLogin"
						placeholder="@nickname"
				></b-form-input>
			</b-form-group>

			<b-form-group id="vpn-input-group"
										v-show="canUseHelpdeskVpnApi && !IS_EXTERNAL() && VUE_APP_PASSPORT_VPN_ATTR && this.user.is_service === 0"
										label="VPN пользователя"
										label-for="user-vpn-input">
				<b-modal
						id="vpn-flush"
						centered
						footer-class="p2"
						title="Подтвердите действие"
						size="sm"
						button-size="sm"
						ok-variant="danger"
						ok-title="Да"
						cancel-title="Нет"
						@ok="plusVpn()"
				>
					{{ hasVpn ? 'Сбросить данные VPN?' : 'Создать аккаунт VPN?' }}
					<br>
					<b-form-group label="логин" v-show="canUseHelpdeskVpnApi"
												label-for="user-vpn-login-input">
						<b-input-group>
							<b-form-input
									id="user-vpn-login-input"
									v-model="user.vpnLogin"
									disabled
							></b-form-input>
							<b-input-group-append>
								<b-button @click="copyToClipBoard(user.vpnLogin)"
													class="os-icon os-icon-copy btn-grey"></b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>

					<b-form-group label="пароль" v-show="canUseHelpdeskVpnApi"
												label-for="user-vpn-pass-input">
						<b-input-group>
							<b-form-input
									id="user-vpn-pass-input"
									v-model="user.vpnPass"
									disabled
							></b-form-input>
							<b-input-group-append>
								<b-button @click="copyToClipBoard(user.vpnPass)"
													class="os-icon os-icon-copy btn-grey"></b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-modal>
				<b-button class="btn btn-grey" v-b-modal="'vpn-flush'">VPN</b-button>
			</b-form-group>

			<b-form-group label="Сервисный пользователь?" v-show="!IS_EXTERNAL()" label-for="user-service-input">
				<b-form-checkbox
						id="user-service-input"
						v-model="user.is_service"
						:value="1"
						:unchecked-value="0"
				></b-form-checkbox>
			</b-form-group>

			<b-form-group label="Сервис пользователя" v-show="user.is_service === 1" label-for="user-system-input">
				<b-form-input
						id="user-system-input"
						v-model="user.system"
						placeholder="Введите название сервиса, к которому относится пользователь"
				></b-form-input>
			</b-form-group>

			<b-form-group id="attribute-input-group" label="Атрибуты пользователя" label-for="user-attribute-input">
				<b-modal
						id="attributes-modal"
						v-model="attributeShow"
						size="lg"
						title="Изменение атрибутов пользователя"
						ok-title="Применить"
						cancel-title="Отмена"
						@cancel="attributeRefresh"
				>
					<b-row class="mb-1 text-center">
						<b-col>Атрибут</b-col>
						<b-col>Значение</b-col>
						<b-col cols="2"></b-col>
					</b-row>
					<div v-for="attr in user.userAttributes" :key="attr.attribute">
						<b-row align-v="center" class="mb-1 text-center">
							<b-col>
								<b-select v-model="attr.attribute">
									<b-select-option
										v-for="(opt, i) in attributeOptions"
										:key="i"
										:value="opt.value"
										:title="opt.title"
										:disabled="opt.disabled"
									>
										{{ opt.text }}
									</b-select-option>
								</b-select>
							</b-col>
							<b-col>
								<b-input v-model="attr.value"></b-input>
							</b-col>
							<b-col cols="2">
								<b-button class="btn btn-danger" @click="attributeDelete(attr)">Удалить</b-button>
							</b-col>
						</b-row>
					</div>
					<b-button class="btn btn-grey" @click="attributeAdd">Добавить</b-button>
				</b-modal>
				<b-button id="user-attribute-input" :disabled="loaded ? disabled : ''" class="btn btn-grey" @click="attributeShow=true">Атрибуты</b-button>
			</b-form-group>

			<b-form-group label="Доступ к офисам" 
				v-show="!IS_EXTERNAL() && this.user.is_service === 0" 
				id="branches-input-group" 
				label-for="user-branches-input"
			>
				<b-form-tags id="user-branches-input" v-model="userBranches" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onBranchDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>
									{{ JSON.parse(tag).name }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление офисов
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск офисов"
										label-for="branch-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchBranchDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchBranches"
											id="branch-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название офиса"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="branch in branchAvailableOptions"
									:key="branch.id"
									@click="onBranchAddClick({ option: branch, addTag: addTag })"
							>{{ branch.name }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="branchAvailableOptions.length === 0">
								Нет прав доступа, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<b-form-group id="groups-input-group" label="Группы прав пользователя" label-for="user-groups-input">
				<b-form-tags id="user-groups-input" v-model="userGroups" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onGroupDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>{{ JSON.parse(tag).name }} - {{ JSON.parse(tag).code }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление групп
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск групп"
										label-for="group-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchGroupsDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchGroups"
											id="group-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название группы прав"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="(group, i) in groupAvailableOptions"
									:key="i"
									:title="group.description"
									@click="onGroupAddClick({ option: group, addTag: addTag })"
							>({{ group.namespace_name }}) {{ group.name }} - {{ group.code }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="groupAvailableOptions.length === 0">
								Нет групп прав, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<b-form-group id="permissions-input-group" label="Права пользователя" label-for="user-permissions-input">
				<b-form-tags id="user-permissions-input" v-model="userPermissions" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onPermissionDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>
									<b-form-checkbox
											v-model="JSON.parse(tag).exclude"
											value="0"
											unchecked-value="1"
											inline
											plain
											@change="onPermissionExcludeChange({ option: tag, addTag: addTag, removeTag: removeTag })"
									></b-form-checkbox>
									{{ JSON.parse(tag).name }} - {{ JSON.parse(tag).code }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление прав
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск прав"
										label-for="permission-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchPermissionsDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchPermissions"
											id="permission-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название права доступа"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="(permission, i) in permissionAvailableOptions"
									:key="i"
									:title="permission.description"
									@click="onPermissionAddClick({ option: permission, addTag: addTag })"
							>({{ permission.namespace_name }}) {{ permission.name }} - {{ permission.code }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="permissionAvailableOptions.length === 0">
								Нет прав доступа, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">
					Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToUserTable()">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';
import generator from 'generate-password';
import {
	IS_EXTERNAL, VUE_APP_PASSPORT_TG_ID_ATTR, VUE_APP_PASSPORT_TG_LOGIN_ATTR,
	VUE_APP_PASSPORT_VPN_ATTR, VUE_APP_PASSPORT_BRANCHES_ATTR
} from '@/config';
import Vue from 'vue';

export default {
	name: 'UserUpdateForm',
	data() {
		return {
			user: {
				login: '',
				password: '',
				name: '',
				tgId: '',
				tgLogin: '',
				disabled: 0,
				is_service: 0,
				system: null,
				vpnLogin: '',
				vpnPass: '',
				resetVpn: false,
				groups: [],
				userPermissions: [],
				userAttributes: []
			},
			user_original: {
				login: '',
				password: '',
				name: '',
				disabled: 0,
				is_service: 0,
				system: null,
				tgId: '',
				tgLogin: '',
				groups: [],
				userPermissions: [],
				userAttributes: []
			},
			userPermissions: [],
			permissionOptions: [],
			searchPermissions: '',

			userGroups: [],
			groupOptions: [],
			searchGroups: '',

			valid: false,
			loaded: false,
			hasVpn: true,

			attributeShow: false,
			attributeOptions: [],
			attributeError: [],

			userBranches: [],
			userOriginalBranches: [],
			branchOptions: [],
			searchBranches: '',
		};
	},

	watch: {
		'user': {
			deep: true,
			handler(cal) {
				if (this.loaded) {
					this.checkFields(cal);
				}
			}
		},
		'userBranches': {
			deep: true,
			handler(cal) {
				if (this.loaded) {
					this.checkFields(cal);
				}
			}
		}
	},

	methods: {
		IS_EXTERNAL,
		...mapActions([
			'UPDATE_USER', 'GET_USERS_FROM_API', 'GET_PERMISSIONS_FROM_API', 'GET_GROUPS_FROM_API', 'GET_NAMESPACES_FROM_API',
			'GET_ATTRIBUTES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY', 'HELPDESK_UPDATE_VPN_USER', 'HELPDESK_CREATE_VPN_USER', 'HELPDESK_GET_BRANCHES_FROM_API'
		]),

		plusVpn() {
			Vue.set(this.user, 'resetVpn', true);
		},

		copyToClipBoard(value) {
			window.navigator.clipboard.writeText(value);
		},

		checkFields(cal) {
			let check_form = {
				login: false,
				name: false,
				tgId: false,
				tgLogin: false,
				attributes: true
			};
			let changed_arr = {
				login: false,
				name: false,
				tgId: false,
				tgLogin: false,
				isService: false,
				group: false,
				permission: false,
				password: false,
				attributes: false,
				branches: false
			};

			if (this.user.login && this.user.login.length > 0) {
				if (this.user.login !== this.user_original.login) {
					changed_arr.login = true;
				}
				check_form.login = true;
			}

			if (this.user.password && this.user.password.length > 0) {
				changed_arr.password = true;
			}

			if (this.user.name && this.user.name.length > 0) {
				if (this.user.name !== this.user_original.name) {
					changed_arr.name = true;
				}
				check_form.name = true;
			}

			if (this.user.tgLogin && this.user.tgLogin.length > 0) {
				if (this.user.tgLogin !== this.user_original.tgLogin) {
					changed_arr.tgLogin = true;
				}
				if ((Array.from(this.user.tgLogin)[0] === '@' && this.user.tgLogin.length > 1) || this.user.tgLogin === '-') {
					check_form.tgLogin = true;
				}
			}

			if (this.user.tgId && this.user.tgId.length > 0) {
				if (this.user.tgId !== this.user_original.tgId) {
					changed_arr.tgId = true;
				}
				if (this.user.tgId.length > 1 || this.user.tgId === '-') {
					check_form.tgId = true;
				}
			}

			changed_arr.isService = this.user.is_service !== this.user_original.is_service;
			if (IS_EXTERNAL() || this.user.is_service) {
				changed_arr.tgLogin = false;
				check_form.tgLogin = true;
				changed_arr.tgId = false;
				check_form.tgId = true;
			}

			let app = this;
			// сохранившиеся старые значения
			let original_from_current = [];
			// значения в виде id
			app.user_original.groups.forEach(function callback(originValue, originIndex, originArray) {
				app.user.groups.forEach(function callback(currentValue, index, array) {
					if (originValue === currentValue) {
						original_from_current.push(currentValue);
					}
				});
			});
			if (app.user.groups.length > original_from_current.length || original_from_current.length !== app.user_original.groups.length) {
				changed_arr.group = true;
			}

			original_from_current = [];
			// значения в виде json строки
			app.user_original.userPermissions.forEach(function callback(originText, originIndex, originArray) {
				let originValue = originText;
				if (typeof originValue != 'object') {
					originValue = JSON.parse(originValue);
				}
				app.user.userPermissions.forEach(function callback(currentText, index, array) {
					let currentValue = currentText;
					if (typeof currentValue != 'object') {
						currentValue = JSON.parse(currentValue);
					}
					if (originValue.name === currentValue.name && originValue.exclude === currentValue.exclude) {
						original_from_current.push(currentValue);
					}
				});
			});
			if (app.user.userPermissions.length > original_from_current.length || original_from_current.length !== app.user_original.userPermissions.length) {
				changed_arr.permission = true;
			}

			if (this.user.userAttributes && this.user.userAttributes.length > 0) {
				app.user.userAttributes.forEach(function (attr) {
					if (attr.attribute === null || attr.value === null || attr.value === '') {
						check_form.attributes = false;
					}
				});
			}

			if (app.userBranches.length > app.userOriginalBranches.length || 
				app.userBranches.length !== app.userOriginalBranches.length || 
				JSON.stringify(app.userBranches) !== JSON.stringify(app.userOriginalBranches)
			) {
				changed_arr.branches = true;
			}

			original_from_current = [];
			// значения в виде id
			app.user_original.userAttributes.forEach(function callback(originValue, originIndex, originArray) {
				app.user.userAttributes.forEach(function callback(currentValue, index, array) {
					if (originValue.attribute === currentValue.attribute && originValue.value === currentValue.value) {
						original_from_current.push(currentValue);
					}
				});
			});
			if (app.user.userAttributes.length > original_from_current.length || original_from_current.length !== app.user_original.userAttributes.length) {
				changed_arr.attributes = true;
			}

			this.valid = check_form.login && check_form.name && check_form.tgLogin && check_form.tgId && check_form.attributes
					&& (changed_arr.password || changed_arr.login || changed_arr.name || changed_arr.tgLogin || changed_arr.tgId ||
							changed_arr.isService || changed_arr.group || changed_arr.permission || changed_arr.attributes || cal.resetVpn === true || changed_arr.branches);
		},

		onPermissionAddClick({option, addTag}) {
			// если право уже добавлено к пользователю - возврат
			if (this.user.userPermissions.findIndex(x => x.permission === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Право доступа уже добавлено'
				});
				return;
			}
			this.user.userPermissions.push({permission: option.id, exclude: option.exclude, name: option.name});

			let id = this.permissionOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.permissionOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchPermissions = '';
		},

		onBranchAddClick({option, addTag}) {
			if (this.userBranches.findIndex(x => x.id === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Офис уже добавлен'
				});
				return;
			}

			this.userBranches.push({id: option.id, name: option.name});

			let id = this.branchOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.branchOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchBranches = '';
		},

		onBranchDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let branchToRemove = JSON.parse(option);

			let isAddedBranch = this.userBranches.findIndex(x => x.id === branchToRemove.id);
			if (isAddedBranch > -1) {
				this.userBranches.splice(isAddedBranch, 1);
			}

			this.branchOptions.push(branchToRemove);
			this.branchOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));

			removeTag(option);
		},

		onPermissionDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let permissionToRemove = JSON.parse(option);

			let idAddedPermission = this.user.userPermissions.findIndex(x => x.permission === permissionToRemove.id);
			if (idAddedPermission > -1) {
				this.user.userPermissions.splice(idAddedPermission, 1);
			}
			this.permissionOptions.push(permissionToRemove);
			this.permissionOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
			removeTag(option);
		},

		onPermissionExcludeChange({option, addTag, removeTag}) {
			let permissionToChangeExclude = JSON.parse(option);
			removeTag(option);
			permissionToChangeExclude.exclude = Number(!permissionToChangeExclude.exclude);

			let idExcludeChangedPermission = this.user.userPermissions.findIndex(x => x.permission === permissionToChangeExclude.id);
			if (idExcludeChangedPermission > -1) {
				this.user.userPermissions[idExcludeChangedPermission].exclude = Number(!this.user.userPermissions[idExcludeChangedPermission].exclude);
			}
			addTag(JSON.stringify(permissionToChangeExclude));
		},

		onGroupAddClick({option, addTag}) {
			// если группа уже добавлена к пользователю - возврат
			if (this.user.groups.findIndex(x => x === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Группа прав доступа уже добавлена'
				});
				return;
			}
			this.user.groups.push(option.id);

			let id = this.groupOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.groupOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchGroups = '';
		},

		onGroupDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let groupToRemove = JSON.parse(option);
			let idAddedGroup = this.user.groups.findIndex(x => x === groupToRemove.id);
			if (idAddedGroup > -1) {
				this.user.groups.splice(idAddedGroup, 1);
			}
			this.groupOptions.push(groupToRemove);
			this.groupOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
			removeTag(option);
		},

		getAttrValue(id, attrs) {
			if (id === '' || IS_EXTERNAL() || !attrs) {
				return '';
			}
			const attr = attrs.find(e => e.attribute === id);
			if (attr) {
				return attr.value;
			}
			return '';
		},

		async addVpn() {
			if (this.user.vpnLogin === '') {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Введите логин пользователя VPN'
				});
				return;
			}
			if (this.hasVpn) {
				await this.HELPDESK_UPDATE_VPN_USER({
					user: this.user.vpnLogin,
					pass: this.user.vpnPass,
				}).then(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'success',
						title: 'Успех',
						message: 'Пользователь VPN успешно сброшен'
					});
				}).catch(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Произошла ошибка при сбросе пользователя VPN'
					});
				});
				return;
			}
			await this.HELPDESK_CREATE_VPN_USER({
				user: this.user.vpnLogin,
				pass: this.user.vpnPass,
			}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Пользователь VPN успешно сброшен'
				});
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при сбросе пользователя VPN'
				});
			});
			this.attributeDelete({attribute: VUE_APP_PASSPORT_VPN_ATTR});
			this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_VPN_ATTR, value: this.user.vpnLogin});
		},

		async addUserBranches() {
			if (this.userBranches && this.userBranches.length > 0) {
				let values = [];
				this.userBranches.forEach(function callback(userBranch) {
					let branch = userBranch;
					if (typeof branch != 'object') {
						branch = JSON.parse(branch);
					}
					values.push(branch.id);
				});
				this.attributeDelete({attribute: VUE_APP_PASSPORT_BRANCHES_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_BRANCHES_ATTR, value: values.join(',')});
			}
		},

		attributeAdd() {
			let app = this;
			this.user.userAttributes.forEach(function (attr) {
				let idAddedAttr = app.attributeOptions.findIndex(x => x.value === attr.attribute);
				if (idAddedAttr > -1) {
					app.attributeOptions[idAddedAttr].disabled = true;
				}
			});
			let i = this.user.userAttributes.length;
			if (i === 0 || this.user.userAttributes[i - 1].attribute !== null && this.user.userAttributes[i - 1].value !== null) {
				this.user.userAttributes.push({attribute: null, value: null});
			}
		},

		attributeDelete(attr) {
			let idAddedAttr = this.user.userAttributes.findIndex(x => x.attribute === attr.attribute);
			if (idAddedAttr > -1) {
				this.user.userAttributes.splice(idAddedAttr, 1);
			}
			let app = this;
			this.user.userAttributes.forEach(function (attr) {
				let idAddedAttr = app.attributeOptions.findIndex(x => x.value === attr.attribute);
				if (idAddedAttr > -1) {
					app.attributeOptions[idAddedAttr].disabled = false;
				}
			});
		},

		attributeRefresh() {
			this.user.userAttributes = [];
			for (let i = 0; i < this.user_original.userAttributes.length; i++) {
				this.user.userAttributes.push({
					attribute: this.user_original.userAttributes[i].attribute,
					value: this.user_original.userAttributes[i].value
				});
			}
		},

		async getNamespacesPermissions() {
			await this.GET_PERMISSIONS_FROM_API({limit: 5000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка прав доступа'
				});
			});
			this.permissionOptions = this.passportPermissions;
			this.permissionOptions.forEach(function (itm) {
				itm.exclude = 0;
			});

			for (let i = 0; i < this.permissionOptions.length; i += 1) {
				let ns = this.passportNamespaces.find(n => n.id === this.permissionOptions[i].namespace_id) || '';
				this.permissionOptions[i].namespace_name = ns.name;
			}

			this.permissionOptions.sort((a, b) => a.namespace_name.normalize().localeCompare(b.namespace_name.normalize()));

			for (let userPerm of this.user.userPermissions) {
				let id = this.permissionOptions.findIndex(x => x.id === userPerm.permission);
				if (id > -1) {
					this.permissionOptions.splice(id, 1);
				}
			}
		},

		async getNamespacedGroups() {
			await this.GET_GROUPS_FROM_API({limit: 1000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка групп прав доступа'
				});
			});
			this.groupOptions = this.passportGroups;
			for (let i = 0; i < this.groupOptions.length; i += 1) {
				let ns = this.passportNamespaces.find(n => n.id === this.groupOptions[i].namespace_id) || '';
				this.groupOptions[i].namespace_name = ns.name;
			}

			this.groupOptions.sort((a, b) => a.namespace_name.normalize().localeCompare(b.namespace_name.normalize()));

			for (let userGroup of this.user.groups) {
				let id = this.groupOptions.findIndex(x => x.id === userGroup);
				if (id > -1) {
					this.groupOptions.splice(id, 1);
				}
			}
		},

		async reloadNamespacedParameters() {
			await Promise.all([this.getNamespacesPermissions(), this.getNamespacedGroups()]);
		},

		async getAttributes() {
			await this.GET_ATTRIBUTES_FROM_API({}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка атрибутов'
				});
			});
			for (let i = 0; i < this.passportAttributes.length; i++) {
				if (!IS_EXTERNAL() && (this.passportAttributes[i].id === VUE_APP_PASSPORT_VPN_ATTR ||
						this.passportAttributes[i].id === VUE_APP_PASSPORT_TG_ID_ATTR ||
						this.passportAttributes[i].id === VUE_APP_PASSPORT_TG_LOGIN_ATTR)) {
					continue;
				}
				this.attributeOptions.push({
					value: this.passportAttributes[i].id,
					text: this.passportAttributes[i].name + ' - ' + this.passportAttributes[i].code,
					title: this.passportAttributes[i].description,
					disabled: false,
				});
			}
		},

		async getBranches() {
			await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000, project: 'all'}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов'
				});
			});
			let userBranches = this.getAttrValue(VUE_APP_PASSPORT_BRANCHES_ATTR, this.user.userAttributes);
			userBranches = userBranches.replace(' ', '').split(',').map(Number);

			for (let i = 0; i < this.helpdeskBranches.length; i++) {
				if (userBranches.includes(this.helpdeskBranches[i].id)) {
					this.userBranches.push({
						id: this.helpdeskBranches[i].id,
						name: this.helpdeskBranches[i].name
					});
					this.userOriginalBranches.push({
						id: this.helpdeskBranches[i].id,
						name: this.helpdeskBranches[i].name
					});
				} else {
					this.branchOptions.push({
						id: this.helpdeskBranches[i].id,
						name: this.helpdeskBranches[i].name
					});
				}
			}
			this.attributeDelete({attribute: VUE_APP_PASSPORT_BRANCHES_ATTR});
		},

		async getPermissions() {
			const permissionPromises = [];
			for (let i = 0; i < this.user.userPermissions.length; i++) {
				if (typeof (this.user.userPermissions[i]) !== 'object') {
					this.user.userPermissions[i] = JSON.parse(this.user.userPermissions[i]);
				}
				permissionPromises.push(this.GET_PERMISSIONS_FROM_API({
					id: this.user.userPermissions[i].permission,
					limit: 1000
				}));
			}
			await Promise.all(permissionPromises).then( passportPermissions => {
				passportPermissions.forEach(permission => {
					const i = this.user.userPermissions.findIndex((p) => p.permission === permission.id);
					if (i > -1) {
						if (typeof (this.user.userPermissions[i]) !== 'object') {
							this.user.userPermissions[i] = JSON.parse(this.user.userPermissions[i]);
						}
						permission.exclude = this.user.userPermissions[i].exclude;
					}
					this.userPermissions.push(permission);
				});
			}).catch( () => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении права доступа'
				});
			});
		},

		async getGroups () {
			const groupPromises = [];
			for (let i = 0; i < this.user.groups.length; i++) {
				groupPromises.push(this.GET_GROUPS_FROM_API({id: this.user.groups[i]}));
			}
			await Promise.all(groupPromises).then( groups => {
				groups.forEach(group => {
					this.userGroups.push(group);
				});
			}).catch( () => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении группы прав доступа'
				});
			});
		},

		backToUserTable() {
			this.$router.push({name: routeNames.passport.users.read, query: this.$route.query});
		},

		genPass() {
			return generator.generate({
				length: 16,
				numbers: true,
				excludeSimilarCharacters: true
			});
		},

		async submit() {
			if (!IS_EXTERNAL() && this.user.is_service === 0 && this.user.tgId && this.user.tgId.length > 0) {
				this.attributeDelete({attribute: VUE_APP_PASSPORT_TG_ID_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_TG_ID_ATTR, value: this.user.tgId});
			}
			if (!IS_EXTERNAL() && this.user.is_service === 0 && this.user.tgLogin && this.user.tgLogin.length > 0) {
				this.attributeDelete({attribute: VUE_APP_PASSPORT_TG_LOGIN_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_TG_LOGIN_ATTR, value: this.user.tgLogin});
			}
			if (!IS_EXTERNAL() && this.user.is_service === 0) {
				await this.addUserBranches();
			}
			if (!IS_EXTERNAL() && this.user.is_service === 0 && this.user.resetVpn) {
				await this.addVpn();
			}
			if (this.user.system === '') {
				this.user.system = null;
			}
			await this.UPDATE_USER({user: this.user}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Пользователь успешно обновлён'
				});
				this.backToUserTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении пользователя'
				});
			});
		},
	},

	computed: {
		VUE_APP_PASSPORT_VPN_ATTR() {
			return VUE_APP_PASSPORT_VPN_ATTR;
		},
		...mapGetters([
			'passportUsers', 'passportPermissions', 'passportGroups', 'passportSU', 'passportNamespaces',
			'passportAttributes', 'canUseHelpdeskVpnApi', 'helpdeskBranches'
		]),
		// Функции для работы поиска
		permissionsCriteria() {
			return this.searchPermissions.trim().toLowerCase();
		},
		permissionAvailableOptions() {
			const criteria = this.permissionsCriteria;
			if (criteria) {
				return this.permissionOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.permissionOptions;
		},
		searchPermissionsDesc() {
			if (this.permissionsCriteria && this.permissionAvailableOptions.length === 0) {
				return 'Нет прав доступа с заданным именем';
			}
			return '';
		},
		groupsCriteria() {
			return this.searchGroups.trim().toLowerCase();
		},
		groupAvailableOptions() {
			const criteria = this.groupsCriteria;
			if (criteria) {
				return this.groupOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.groupOptions;
		},
		searchGroupsDesc() {
			if (this.groupsCriteria && this.groupAvailableOptions.length === 0) {
				return 'Нет групп с заданным именем';
			}
			return '';
		},
		branchesCriteria() {
			return this.searchBranches.trim().toLowerCase();
		},
		branchAvailableOptions() {
			const criteria = this.branchesCriteria;
			if (criteria) {
				return this.branchOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.branchOptions;
		},
		searchBranchDesc() {
			if (this.branchesCriteria && this.branchAvailableOptions.length === 0) {
				return 'Нет прав доступа с заданным именем';
			}
			return '';
		},
	},

	async mounted() {
		await this.GET_NAMESPACES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка неймспейсов'
			});
		});

		if (this.$route.params.user_id !== '0') {
			await this.GET_USERS_FROM_API({id: this.$route.params.user_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении пользователя'
				});
			});

			this.user = Object.assign({}, this.passportUsers[0]);
			Vue.set(this.user, 'vpnPass', this.genPass());

			const vpnLogin = this.getAttrValue(VUE_APP_PASSPORT_VPN_ATTR, this.user.userAttributes);
			if (vpnLogin !== '') {
				Vue.set(this.user, 'vpnLogin', vpnLogin);
			} else {
				Vue.set(this.user, 'vpnLogin', this.user.login);
				this.hasVpn = false;
			}
			this.attributeDelete({attribute: VUE_APP_PASSPORT_VPN_ATTR});

			const tgLogin = this.getAttrValue(VUE_APP_PASSPORT_TG_LOGIN_ATTR, this.user.userAttributes);
			if (tgLogin !== '') {
				Vue.set(this.user, 'tgLogin', tgLogin);
			}
			this.attributeDelete({attribute: VUE_APP_PASSPORT_TG_LOGIN_ATTR});

			const tgId = this.getAttrValue(VUE_APP_PASSPORT_TG_ID_ATTR, this.user.userAttributes);
			if (tgId !== '') {
				Vue.set(this.user, 'tgId', tgId);
			}
			this.attributeDelete({attribute: VUE_APP_PASSPORT_TG_ID_ATTR});

			this.user.userAttributes.sort((a, b) => a.code.normalize().localeCompare(b.code.normalize()));

			await Promise.all([
				this.getAttributes(),
				this.getGroups(),
				this.getPermissions(),
				this.getBranches(),
				this.reloadNamespacedParameters(),
			]);

			this.user_original.login = this.user.login;
			this.user_original.name = this.user.name;
			this.user_original.vpnLogin = this.user.vpnLogin;
			this.user_original.tgLogin = this.user.tgLogin;
			this.user_original.tgId = this.user.tgId;
			this.user_original.is_service = this.user.is_service;
			for (let i = 0; i < this.user.groups.length; i++) {
				this.user_original.groups.push(this.user.groups[i]);
			}
			for (let i = 0; i < this.user.userPermissions.length; i++) {
				this.user_original.userPermissions.push({
					permission: this.user.userPermissions[i].permission,
					name: this.user.userPermissions[i].name,
					exclude: this.user.userPermissions[i].exclude
				});
			}
			for (let i = 0; i < this.user.userAttributes.length; i++) {
				this.user_original.userAttributes.push({
					attribute: this.user.userAttributes[i].attribute,
					value: this.user.userAttributes[i].value
				});
			}
			this.loaded = true;
		}
	},
};
</script>

<style>
.user-update-form .dropdown-menu {
	max-height: 350px !important;
	border: 1px solid #000000 !important;
}
</style>
